<template>
  <div>
    <!-- MOBILE -->
    <div class="mb-5 pb-10" style="height:100%">
      <div v-if="reload" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="100%"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 3"
          :key="n+'A'"
        >
        <v-skeleton-loader
          type="card"
        ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <v-expansion-panels v-model="openExpand"  class="round">
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>File Log</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>VDC mengikuti prosedur standar menggunakan file log. File-file ini mencatat pengunjung ketika mereka mengunjungi situs web. Semua perusahaan hosting melakukan ini dan merupakan bagian dari analisis layanan hosting. Informasi yang dikumpulkan oleh file log termasuk alamat protokol internet (IP), jenis browser, Penyedia Layanan Internet (ISP), cap tanggal dan waktu, halaman rujukan/keluar, dan mungkin jumlah klik. Ini tidak terkait dengan informasi apa pun yang dapat diidentifikasi secara pribadi. Tujuan dari informasi ini adalah untuk menganalisis tren, mengelola situs, melacak pergerakan pengguna di situs web, dan mengumpulkan informasi demografis. Kebijakan Privasi kami dibuat dengan bantuan Generator Kebijakan Privasi dan Generator Kebijakan Privasi.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>Cookie dan Web Beacon</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="active--class">
              <p>Seperti situs web lainnya, VDC menggunakan 'cookies'. Cookie ini digunakan untuk menyimpan informasi termasuk preferensi pengunjung, dan halaman di situs web yang diakses atau dikunjungi pengunjung. Informasi tersebut digunakan untuk mengoptimalkan pengalaman pengguna dengan menyesuaikan konten halaman web kami berdasarkan jenis browser pengunjung dan/atau informasi lainnya. Untuk informasi lebih umum tentang cookie, silakan baca artikel “Apa Itu Cookie” di situs web Persetujuan Cookie</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>Cookie Google DoubleClick DART</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="active--class">
              <p>Google adalah salah satu vendor pihak ketiga di situs kami. Ini juga menggunakan cookie, yang dikenal sebagai cookie DART, untuk menayangkan iklan kepada pengunjung situs kami berdasarkan kunjungan mereka ke www.vicedigitalcreative.com dan situs lain di internet. Namun, pengunjung dapat memilih untuk menolak penggunaan cookie DART dengan mengunjungi Kebijakan Privasi jaringan iklan dan konten Google di URL berikut – https://policies.google.com/technologies/ads</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>Mitra Periklanan Kami</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="active--class">
              <p>Beberapa pengiklan di situs kami mungkin menggunakan cookie dan suar web. Mitra periklanan kami tercantum di bawah ini. Setiap mitra periklanan kami memiliki Kebijakan Privasi mereka sendiri untuk kebijakan mereka tentang data pengguna. Untuk akses yang lebih mudah, kami menautkan ke Kebijakan Privasi mereka seperti Google (https://policies.google.com/technologies/ads).</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>Kebijakan Privasi</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="active--class">
              <p>Anda dapat berkonsultasi dengan daftar ini untuk menemukan Kebijakan Privasi masing-masing mitra periklanan VDC. Server iklan atau jaringan iklan pihak ketiga menggunakan teknologi seperti cookie, JavaScript, atau Web Beacon yang digunakan dalam iklan masing-masing dan tautan yang muncul di VDC, yang dikirim langsung ke browser pengguna. Mereka secara otomatis menerima alamat IP Anda ketika ini terjadi. Teknologi ini digunakan untuk mengukur efektivitas kampanye iklan mereka dan/atau untuk mempersonalisasi konten iklan yang Anda lihat di situs web yang Anda kunjungi. Perhatikan bahwa VDC tidak memiliki akses atau kontrol terhadap cookie ini yang digunakan oleh pengiklan pihak ketiga.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>Kebijakan Privasi Pihak Ketiga</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="active--class">
              <p>Kebijakan Privasi VDC tidak berlaku untuk pengiklan atau situs web lain. Oleh karena itu, kami menyarankan Anda untuk berkonsultasi dengan Kebijakan Privasi masing-masing dari server iklan pihak ketiga ini untuk informasi lebih rinci. Ini mungkin termasuk praktik dan instruksi mereka tentang cara memilih keluar dari opsi tertentu. Anda dapat memilih untuk menonaktifkan cookie melalui opsi browser individual Anda. Untuk mengetahui informasi lebih rinci tentang manajemen cookie dengan browser web tertentu, dapat ditemukan di situs web masing-masing browser.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>Informasi Anak</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="active--class">
              <p>Bagian lain dari prioritas kami adalah menambahkan perlindungan bagi anak-anak saat menggunakan internet. Kami mendorong orang tua dan wali untuk mengamati, berpartisipasi, dan/atau memantau dan memandu aktivitas online mereka. VDC tidak secara sadar mengumpulkan Informasi Identifikasi Pribadi apa pun dari anak di bawah 13 tahun. Jika Anda berpikir bahwa anak Anda memberikan informasi semacam ini di situs web kami, kami sangat menganjurkan Anda untuk menghubungi kami segera dan kami akan melakukan upaya terbaik kami untuk segera menghapusnya. informasi tersebut dari catatan kami.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>Hanya Kebijakan Privasi Online</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="active--class">
              <p>Kebijakan Privasi ini hanya berlaku untuk aktivitas online kami dan berlaku untuk pengunjung situs web kami sehubungan dengan informasi yang mereka bagikan dan/atau kumpulkan di VDC. Kebijakan ini tidak berlaku untuk informasi apa pun yang dikumpulkan secara offline atau melalui saluran selain situs web ini.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>Persetujuan</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="active--class">
              <p>Dengan menggunakan situs web kami, Anda dengan ini menyetujui Kebijakan Privasi kami dan menyetujui Syarat dan Ketentuannya.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <p class="text-center mt-5">Jangan tunggu besok, langsung chat admin VDC sekarang ya!</p>
        <div class="d-flex justify-center">
          <v-btn @click="goToWa()" color="primary" rounded class="text-capitalize"><i class="text-18 fab fa-whatsapp mr-2"></i>Chat Admin</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      reload:true,
      isMobileWidth:0,
      openExpand :''
    }
  },
  mounted(){
    if(this.isMobileWidth === 0){
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
          this.reload = false
      } else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
      window.scrollTo(0,0);
    }

    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })

    if(this.isMobileWidth === 0){
      this.openExpand = 0
    } else {
      this.openExpand = ''
    }
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    goToWa(){
      window.open('https://wa.me/6285691701929?text=Halo Admin, Boleh dijelaskan lebih detail terkait service yang ada di VDC ini? \n Terima kasih', '_blank');
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
