<template>
  <div>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <v-row class="ma-4 mt-0 pt-0">
        <v-col cols="12">
          <privacy-page></privacy-page>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PrivacyPage from './components/Privacy.vue'
import axios from 'axios'

export default {
  name: 'pages',
  metaInfo: {
    title: 'Privacy Policy'
  },
  components: {
    PrivacyPage
  },
  setup() {
    return {
      isLoading:false,
    }
  },
  mounted(){
  },
  methods:{
  }
}
</script>
